import { createSlice } from "@reduxjs/toolkit";

import { getSearchResults } from "./search.actions";
import { STATUS } from "../../types";
import { Anime } from "../animeDetails/animeDetails.types";
import { SearchState } from "./search.types";

const initialState = {
  loading: STATUS.IDLE,
  searchList: [] as Anime[],
  searchParam: "",
} as SearchState;
const searchStateReducer = createSlice({
  name: "searchState",
  initialState,
  reducers: {
    setSearchParam: (state, { payload }) => {
      return { ...state, searchParam: payload };
    },
    setSearchLoading: (state, { payload }) => {
      return { ...state, loading: payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchResults.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getSearchResults.rejected, (state) => {
      state.loading = STATUS.FAILED;
      window.location.replace("/login");
    });
    builder.addCase(getSearchResults.fulfilled, (state, { payload }) => {
      state.searchList = payload;
      state.loading = STATUS.SUCCESS;
    });
  },
});

export const { setSearchLoading, setSearchParam } = searchStateReducer.actions;
export default searchStateReducer.reducer;
