import UserListGrid from "./grid";
import { UserContainer } from "./styles";

const UserList = ({ users }: { users: any[] }) => {
  return (
    <UserContainer>
      <UserListGrid users={users} />
      <p style={{ color: "white" }}>
        {users.length} {users.length === 1 ? "viewer" : "viewers"}
      </p>
    </UserContainer>
  );
};

export default UserList;
