import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../types";

import { getAnimeByGenre } from "./explore.actions";
import { ExploreState } from "./explore.types";

const initialState = {} as ExploreState;
const exploreStateReducer = createSlice({
  name: "exploreState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnimeByGenre.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getAnimeByGenre.rejected, (state) => {
      state.loading = STATUS.FAILED;
      window.location.replace("/login");
    });
    builder.addCase(getAnimeByGenre.fulfilled, (state, { payload }) => {
      state.animeList = payload;
      state.loading = STATUS.SUCCESS;
    });
  },
});

export default exploreStateReducer.reducer;
