export const millisToReadable = (millis: number): string => {
  let h, m;
  h = Math.floor(millis / 1000 / 60 / 60);
  m = Math.floor((millis / 1000 / 60 / 60 - h) * 60);

  m < 10 ? (m = `0${m}`) : (m = `${m}`);
  h < 10 ? (h = `0${h}`) : (h = `${h}`);

  if (h !== "00") return `${h}h ${m}m`;
  else return `${m}m`;
};
