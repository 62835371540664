import { SortTooltips } from "../../components";
import { SvgStyle } from "../../styles";
import { SORT_BY, SORT_ORDER } from "../../misc";

const SearchSortGroup = ({
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}: {
  sortBy: SORT_BY;
  sortOrder: SORT_ORDER;
  setSortBy: (a: SORT_BY) => void;
  setSortOrder: (a: SORT_ORDER) => void;
}) => {
  const handleSortOrder = (sortOrder: SORT_ORDER) => {
    setSortOrder(sortOrder);
    localStorage.setItem("sortOrder", `${sortOrder}`);
  };

  const handleSortBy = (sortBy: SORT_BY) => {
    setSortBy(sortBy);
    localStorage.setItem("sortBy", `${sortBy}`);
  };

  return (
    <>
      <SortTooltips />

      {sortOrder === SORT_ORDER.ASC ? (
        <SvgStyle
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="red"
          width={20}
          data-tip
          data-for="sort-rev"
          onClick={() => handleSortOrder(SORT_ORDER.DESC)}
        >
          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
        </SvgStyle>
      ) : (
        <SvgStyle
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="red"
          width={20}
          data-tip
          data-for="sort-rev"
          onClick={() => handleSortOrder(SORT_ORDER.ASC)}
        >
          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
        </SvgStyle>
      )}

      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={sortBy === SORT_BY.TITLE ? "#ef4444" : "#a8a29e"}
        width={20}
        data-tip
        data-for="sort-title"
        onClick={() => handleSortBy(SORT_BY.TITLE)}
      >
        <path
          fillRule="evenodd"
          d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
          clipRule="evenodd"
        />
      </SvgStyle>
      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={sortBy === SORT_BY.YEAR ? "#ef4444" : "#a8a29e"}
        width={20}
        data-tip
        data-for="sort-year"
        onClick={() => handleSortBy(SORT_BY.YEAR)}
      >
        <path
          fillRule="evenodd"
          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
          clipRule="evenodd"
        />
      </SvgStyle>
      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={sortBy === SORT_BY.RATING ? "#ef4444" : "#a8a29e"}
        width={20}
        data-tip
        data-for="sort-rating"
        onClick={() => handleSortBy(SORT_BY.RATING)}
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </SvgStyle>
      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={sortBy === SORT_BY.POPULARITY ? "#ef4444" : "#a8a29e"}
        width={20}
        data-tip
        data-for="sort-popularity"
        onClick={() => handleSortBy(SORT_BY.POPULARITY)}
      >
        <path
          fillRule="evenodd"
          d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
          clipRule="evenodd"
        />
      </SvgStyle>
    </>
  );
};

export default SearchSortGroup;
