import styled from "styled-components";
import theme from "../../../themes/default.json";

export const MobHeadingStyle = styled.p`
  color: ${theme.colors.red_500};
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 1rem;
  text-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 6px 16px rgb(0 0 0 / 12%),
    0 9px 28px rgb(0 0 0 / 7%);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const MobSubHeadingStyle = styled.p`
  color: ${theme.colors.red_500};
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bold;
  text-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 6px 16px rgb(0 0 0 / 12%),
    0 9px 28px rgb(0 0 0 / 7%);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
