import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { kurumiApi } from "../../api";

export const getAnimeData = createAsyncThunk(
  "animeState/getAnimeData",
  async (animeId: number) => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/anime/${animeId}?isMal=false`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
        anilist_token: `Bearer ${cookies.get("anilist_token")}`,
      },
    });

    const { result } = data;
    return result;
  }
);

export const getAnimeEpisodes = createAsyncThunk(
  "animeState/getAnimeEpisodes",
  async ({ animeId, pageNo }: { animeId: number; pageNo: number }) => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(
      `v2/anime/${animeId}/episodes?page_no=${pageNo}&per_page=50`,
      {
        headers: {
          Authorization: `Bearer ${cookies.get("access_token")}`,
        },
      }
    );

    const { result } = data;
    return { ...result, pageNo };
  }
);
