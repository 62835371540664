import styled from "styled-components";

export const UserContainer = styled.div`
  width: 3.5rem;
  height: 3.5rem;
`;

export const UserPfp = styled.img<{ roleColor?: string }>`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.125rem solid ${({ roleColor }) => roleColor ?? "white"};
  padding: 0.125rem;
`;
