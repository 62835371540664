import { SvgStyle } from "../../styles";
import { PaginationTooltips } from "../../components";

const PaginationTabs = ({
  hasPrevious,
  hasNext,
  handleFirst,
  handlePrevious,
  handleNext,
  handleLast,
}: {
  hasPrevious: boolean;
  hasNext: boolean;
  handleFirst: () => void;
  handlePrevious: () => void;
  handleNext: () => void;
  handleLast: () => void;
}) => {
  return (
    <div>
      <PaginationTooltips />
      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        data-tip
        data-for="pagination-first"
        fill={hasPrevious ? "#ef4444" : "#a8a29e"}
        width={24}
        // hoverColor={
        //   hasPrevious
        //     ? theme.colors.primaryLight
        //     : theme.colors.disableGrayLight
        // }
        onClick={handleFirst}
      >
        <path
          fillRule="evenodd"
          d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
          clipRule="evenodd"
        />
      </SvgStyle>
      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        data-tip
        data-for="pagination-previous"
        fill={hasPrevious ? "#ef4444" : "#a8a29e"}
        width={24}
        // hoverColor={
        //   hasPrevious
        //     ? theme.colors.primaryLight
        //     : theme.colors.disableGrayLight
        // }
        onClick={handlePrevious}
      >
        <path
          fillRule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </SvgStyle>
      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        data-tip
        data-for="pagination-next"
        fill={hasNext ? "#ef4444" : "#a8a29e"}
        width={24}
        // hoverColor={
        //   hasNext ? theme.colors.primaryLight : theme.colors.disableGrayLight
        // }
        onClick={handleNext}
      >
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </SvgStyle>
      <SvgStyle
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        data-tip
        data-for="pagination-last"
        width={24}
        fill={hasNext ? "#ef4444" : "#a8a29e"}
        // hoverColor={
        //   hasNext ? theme.colors.primaryLight : theme.colors.disableGrayLight
        // }
        onClick={handleLast}
      >
        <path
          fillRule="evenodd"
          d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </SvgStyle>
    </div>
  );
};

export default PaginationTabs;
