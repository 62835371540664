import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../types";

import { getScheduleResults } from "./schedule.actions";
import { ScheduleState } from "./schedule.types";

const initialState = {} as ScheduleState;
const scheduleStateReducer = createSlice({
  name: "scheduleState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScheduleResults.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getScheduleResults.rejected, (state) => {
      state.loading = STATUS.FAILED;
      window.location.replace("/login");
    });
    builder.addCase(getScheduleResults.fulfilled, (state, { payload }) => {
      state.animeList = payload;
      state.loading = STATUS.SUCCESS;
    });
  },
});

export default scheduleStateReducer.reducer;
