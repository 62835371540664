import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import appStateReducer from "./app/reducer";
import userStateReducer from "./user/reducer";
import searchStateReducer from "./search/search.reducer";
import homeStateReducer from "./home/home.reducer";
import scheduleStateReducer from "./schedule/schedule.reducer";
import animeDetailsStateReducer from "./animeDetails/animeDetails.reducer";
import exploreStateReducer from "./explore/explore.reducer";
import watchPartyStateReducer from "./watchParty/watchParty.reducer";

export const rootReducer = combineReducers({
  app: appStateReducer,
  user: userStateReducer,
  animeState: searchStateReducer,
  homeState: homeStateReducer,
  scheduleState: scheduleStateReducer,
  animeDetailsState: animeDetailsStateReducer,
  exploreState: exploreStateReducer,
  watchPartyState: watchPartyStateReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
