import styled from "styled-components";

export const ChatContainer = styled.div`
  display: flex;
  flex-flow: column;
  border: 1px solid #292929;
  background-color: #151515bb;
  border-radius: 0.375rem;
  padding: 0.375rem;
  height: 70vh;
`;

export const ChatMessages = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  overflow-y: auto;
  flex-grow: 1;
`;

export const ChatInput = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding: 0.375rem;
  background-color: #1a1a1a;
  caret-color: #ef4444;
  resize: none;
  color: white;
  border: 1px solid #292929;
  border-radius: 0.375rem;
  outline: none;
  flex-grow: 1;
`;
