import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

import { kurumiApi } from "../../api";

export const getSearchResults = createAsyncThunk(
  "animeState/getSearchResults",
  async (searchParam: string) => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/anime?q=${searchParam}`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    const { result } = data;
    return result;
  }
);
