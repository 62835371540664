import styled from "styled-components";

export const FAB = styled.div`
  z-index: 20;
`;

export const Container = styled.div<{ visible: boolean }>`
  background-color: #1f1f1f;
  border: 1px solid #2a2a2a;
  border-radius: 0.4rem;
  width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 20;
  padding: 0.5rem;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? "" : "hidden")};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 40%),
    0 9px 28px 8px rgb(0 0 0 / 32%);
  transform: ${({ visible }) => (visible ? "scale(1)" : "scale(0.9)")};
  transition: all 0.2s ease-in-out;
`;

export const StatusContainer = styled.span`
  display: inline-block;
  border-radius: 0.375rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: middle;
    transition: color 0.2s ease-in-out;
  }
`;

export const StatusIcon = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  margin-right: 4px;
  margin-right: 0.6rem;
`;

export const StatusText = styled.span`
  color: #f5f5f4;
  font-weight: 600;
  font-size: 0.8rem;
`;

export const StatusMenu = styled.div`
  margin-left: 0.5rem;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(0.5rem, 1fr));
`;
