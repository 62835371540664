import styled from "styled-components";

export const UserContainer = styled.div`
  border: 1px solid #292929;
  border-radius: 0.375rem;
  background-color: #151515bb;
  padding: 0.5rem;

  p {
    margin: 0.5rem 0 0 0;
  }
`;
