import styled from "styled-components";

export const HeadingStyle = styled.p`
  display: -webkit-box;
  color: #ef4444;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1550px) {
    font-size: 1.8rem;
  }
`;

export const SubHeadingStyle = styled.p<{ showPointer?: boolean }>`
  color: #fca5a5;
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  text-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 6px 16px rgb(0 0 0 / 12%),
    0 9px 28px rgb(0 0 0 / 7%);
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ showPointer }) => (showPointer ? "pointer" : "normal")};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
