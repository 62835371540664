import styled from "styled-components";

export const InputField = styled.div<{ isActive: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  position: relative;
  transition: 0.3s box-shadow ease-out;
  box-shadow: ${({ isActive }) => (isActive ? "0px 3px 0px 0px #ef4444" : "")};

  &:hover {
    box-shadow: ${({ isActive }) =>
      isActive ? "0px 3px 0px 0px #ef4444" : "0px 2px 0px 0px #ef4444"};
  }
`;

export const InputBox = styled.input`
  width: 100%;
  height: 60px;
  position: relative;
  padding: 0 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #ddd;
  outline: none;
  transition: 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;

  &:hover .input-field {
    box-shadow: 0px 2px 0px 0px #ef4444;
  }
`;
