import styled from "styled-components";

export const EmoteButtonContainer = styled.div`
  background-color: #1f1f1f;
  border: 1px solid #2a2a2a;
  border-radius: 0.375rem;
  box-sizing: border-box;
  width: 3rem;
  height: 3rem;
  z-index: 20;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 40%), 0 6px 16px 0 rgb(0 0 0 / 32%),
    0 9px 28px 8px rgb(0 0 0 / 24%);
`;

export const Container = styled.div<{ visible: boolean }>`
  background-color: #1f1f1f;
  border: 1px solid #2a2a2a;
  border-radius: 0.325rem;
  width: 21.5rem;
  height: 13rem;
  position: absolute;
  right: 1rem;
  z-index: 20;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? "" : "hidden")};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 40%),
    0 9px 28px 8px rgb(0 0 0 / 32%);
  transform: ${({ visible }) => (visible ? "scale(1)" : "scale(0.9)")};
  transition: all 0.2s ease-in-out;
  overflow-y: auto;
`;

export const EmoteButton = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;

  svg {
    fill: #ffffff;
    width: 1.4rem;
    height: 100%;
  }
`;

export const EmoteContainer = styled.div`
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr));
  grid-gap: 0.75rem;
`;

export const Emote = styled.img`
  width: 3rem;
  height: 3rem;
  text-align: center;
  text-decoration: none;
  object-fit: contain;
  svg {
    margin: auto;
    stroke: #ffffff;
    width: 1.4rem;
    height: 1.4rem;
    vertical-align: -webkit-baseline-middle;
  }
`;

export const DumbMenuItem = styled.div`
  width: 3rem;
  height: 3rem;
  text-align: center;

  svg {
    margin: auto;
    stroke: #ffffff;
    width: 1.4rem;
    height: 1.4rem;
    vertical-align: -webkit-baseline-middle;
  }
`;
