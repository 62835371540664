import { Flipped } from "react-flip-toolkit";

import {
  Overlay,
  Container,
  Poster,
  TextContainer,
  TextTitle,
  TextYear,
  TextDescription,
} from "./styles";
import { Anime } from "../../../misc";

const AnimeElement = ({
  _id,
  title,
  coverImage,
  year,
  epNumber,
  description,
  bannerImage,
  onHover,
  isLink,
  handleClick,
}: Anime & {
  onHover?: (bannerImage: string) => void;
  isLink?: boolean;
  handleClick?: (animeId: number) => void;
}) => (
  <Flipped flipId={_id}>
    <Container
      onMouseEnter={() => onHover && onHover(bannerImage)}
      onMouseLeave={() => onHover && onHover("")}
      style={{ textDecoration: "none" }}
      to={
        isLink
          ? epNumber
            ? `/anime/${_id}/episode/${epNumber}`
            : `/anime/${_id}`
          : "#"
      }
      onClick={() => !isLink && handleClick!(_id)}
    >
      <Poster
        className="poster"
        src={coverImage.extraLarge ?? coverImage.large}
        alt={title.romaji}
      />
      <TextContainer>
        <TextTitle>{title.romaji}</TextTitle>
        <TextYear>{year}</TextYear>
      </TextContainer>
      <Overlay className="overlay">
        <TextDescription dangerouslySetInnerHTML={{ __html: description }} />
      </Overlay>
    </Container>
  </Flipped>
);

export default AnimeElement;
