import ReactTooltip from "react-tooltip";

const SortTooltips = () => {
  return (
    <>
      <ReactTooltip
        id="sort-rev"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Sort in reverse order</p>
      </ReactTooltip>

      <ReactTooltip
        id="sort-relevance"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Sort by relevance</p>
      </ReactTooltip>

      <ReactTooltip
        id="sort-title"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Sort by title</p>
      </ReactTooltip>

      <ReactTooltip
        id="sort-year"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Sort by year</p>
      </ReactTooltip>

      <ReactTooltip
        id="sort-rating"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Sort by rating</p>
      </ReactTooltip>

      <ReactTooltip
        id="sort-popularity"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Sort by popularity</p>
      </ReactTooltip>
    </>
  );
};

export default SortTooltips;
