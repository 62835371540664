import LoginContainer from "../../ui/containers/Login";
import MobLoginContainer from "../../ui/containers/Login/mob";
import useLoginPage from "./hooks";

const LoginPage = ({ isMobile }: { isMobile: boolean }) => {
  const { loading } = useLoginPage();

  return isMobile ? (
    <MobLoginContainer loading={loading} />
  ) : (
    <LoginContainer loading={loading} />
  );
};

export default LoginPage;
