import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { kurumiApi } from "../../api";

export const getAnimeByGenre = createAsyncThunk(
  "animeState/getScheduleResults",
  async (genre: string) => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.post(
      `v2/anime/genre`,
      { genre },
      {
        headers: {
          Authorization: `Bearer ${cookies.get("access_token")}`,
        },
      }
    );

    return data.result;
  }
);
