import { MutableRefObject, useEffect } from "react";

export const useOutsideClickHook = (
  ref: MutableRefObject<any>,
  cb: () => any
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) =>
      ref.current && !ref.current.contains(event.target) && cb();

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cb, ref]);
};
