import { Redirect, Route } from "react-router-dom";
import { useEffect } from "react";
import { userAuthCheck } from "../../store/user/actions";

import { MobRootContainerStyle } from "../styles/mob/rootContainerStyles";
import { Footer } from "./PrivateRouteStyles";

import footer from "../../assets/images/footer.png";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { STATUS } from "../../types";
import { Loading } from "../components";

const PrivateRoute = ({
  component: Component,
  isMobile,
  ...rest
}: {
  component: any;
  isMobile: boolean;
}) => {
  const user = useSelector<RootState, RootState["user"]["user"]>(
    (state) => state.user.user
  );
  const loading = useSelector<RootState, RootState["user"]["loading"]>(
    (state) => state.user.loading
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userAuthCheck());
  }, [dispatch]);

  return (
    <Route
      {...rest}
      render={(props) =>
        !document.cookie ? (
          <Redirect to="/login" />
        ) : (
          <>
            {loading === STATUS.SUCCESS && user._id && (
              <>
                {isMobile ? (
                  <MobRootContainerStyle>
                    <Component {...props} isMobile={true} />
                  </MobRootContainerStyle>
                ) : (
                  // <RootContainerStyle>
                  <Component {...props} isMobile={false} />
                  // </RootContainerStyle>
                )}
                <Footer footer={footer}>
                  <p>
                    © 2021 AniKuru |{"    "}
                    <a
                      href="/team.txt"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      team.txt
                    </a>
                    {"    "}|{"    "}
                    <a
                      href="/info.txt"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      info.txt
                    </a>
                    {"    "}|{"     "}
                    <a
                      href="https://status.anikuru.me/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      status
                    </a>{" "}
                  </p>
                </Footer>
              </>
            )}
            {loading === STATUS.LOADING && (
              <div style={{ display: "grid", height: "100vh" }}>
                <Loading />
              </div>
            )}
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
