import { useState } from "react";

import {
  EmoteButtonContainer,
  Container,
  EmoteContainer,
  Emote,
  EmoteButton,
} from "./styles";

const EmoteSelector = ({
  handleSelectEmote,
}: {
  handleSelectEmote: (id: string) => void;
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const emotes = [
    {
      url: "https://cdn.discordapp.com/emojis/841176042051600424.png",
      id: "841176042051600424",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841176289750417438.gif",
      id: "841176289750417438",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841180094411309067.png",
      id: "841180094411309067",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841184768476315689.png",
      id: "841184768476315689",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841184768599130142.png",
      id: "841184768599130142",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841184768615514142.png",
      id: "841184768615514142",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841366061583695953.png",
      id: "841366061583695953",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841366132546863175.png",
      id: "841366132546863175",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841366162725666836.png",
      id: "841366162725666836",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841484704300793897.png",
      id: "841484704300793897",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841484704557039636.png",
      id: "841484704557039636",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841485046157410304.png",
      id: "841485046157410304",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841492956371353610.png",
      id: "841492956371353610",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841516486736478218.png",
      id: "841516486736478218",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841627515600109618.png",
      id: "841627515600109618",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841627564233195541.png",
      id: "841627564233195541",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841627602892488704.png",
      id: "841627602892488704",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841668721914413077.gif",
      id: "841668721914413077",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841726283532730418.png",
      id: "841726283532730418",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841731267354951700.gif",
      id: "841731267354951700",
    },
    {
      url: "https://cdn.discordapp.com/emojis/841731491444162601.gif",
      id: "841731491444162601",
    },
    {
      url: "https://cdn.discordapp.com/emojis/842124362109943828.png",
      id: "842124362109943828",
    },
    {
      url: "https://cdn.discordapp.com/emojis/842176399833432084.png",
      id: "842176399833432084",
    },
    {
      url: "https://cdn.discordapp.com/emojis/842176973370294314.png",
      id: "842176973370294314",
    },
    {
      url: "https://cdn.discordapp.com/emojis/842180616950775810.png",
      id: "842180616950775810",
    },
    {
      url: "https://cdn.discordapp.com/emojis/842180634599751710.png",
      id: "842180634599751710",
    },
    {
      url: "https://cdn.discordapp.com/emojis/843117923442753567.png",
      id: "843117923442753567",
    },
  ];

  return (
    <>
      <EmoteButtonContainer onClick={() => setOpen(!isOpen)}>
        <EmoteButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="#fff"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
              clipRule="evenodd"
            />
          </svg>
        </EmoteButton>
      </EmoteButtonContainer>
      <Container visible={isOpen}>
        <EmoteContainer>
          {emotes.map((e) => (
            <Emote
              onClick={() => {
                setOpen(false);
                handleSelectEmote(e.id);
              }}
              src={e.url}
              alt=""
            ></Emote>
          ))}
        </EmoteContainer>
      </Container>
    </>
  );
};

export default EmoteSelector;
