export const genres: {
  [key: string]: { genre: string; slug: string; poster: string };
} = {
  action: {
    genre: "Action",
    slug: "action",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx113415-979nF8TZP2xC.jpg",
  },
  adventure: {
    genre: "Adventure",
    slug: "adventure",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx21355-xsLpqMFkMQQk.png",
  },
  comedy: {
    genre: "Comedy",
    slug: "comedy",
    poster:
      "https://vistapointe.net/images/great-teacher-onizuka-wallpaper-6.jpg",
  },
  drama: {
    genre: "Drama",
    slug: "drama",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx11887-AJezDTY27LxZ.png",
  },
  ecchi: {
    genre: "Ecchi",
    slug: "ecchi",
    poster: "https://m.media-amazon.com/images/I/91fzfdBwA5L.jpg",
  },
  fantasy: {
    genre: "Fantasy",
    slug: "fantasy",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx106286-5COcpd0J9VbL.png",
  },
  horror: {
    genre: "Horror",
    slug: "horror",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/medium/13807.jpg",
  },
  "mahou-shoujo": {
    genre: "Mahou Shoujo",
    slug: "mahou-shoujo",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx9756-oqKE3E6DLtgm.jpg",
  },
  mecha: {
    genre: "Mecha",
    slug: "mecha",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx30-1Ro1NFFg28bu.jpg",
  },
  music: {
    genre: "Music",
    slug: "music",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx5680-Xh6B67KuZ2PU.png",
  },
  mystery: {
    genre: "Mystery",
    slug: "mystery",
    poster:
      "https://images-na.ssl-images-amazon.com/images/I/81L4UXKIiPL._SL1500_.jpg",
  },
  psychological: {
    genre: "Psychological",
    slug: "psychological",
    poster:
      "https://i.pinimg.com/736x/3a/04/3d/3a043d713e57c304137d4567cfc6a636.jpg",
  },
  romance: {
    genre: "Romance",
    slug: "romance",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/nx102882-lKp3ExWNzoE6.jpg",
  },
  "sci-fi": {
    genre: "Sci-Fi",
    slug: "sci-fi",
    poster:
      "https://i.pinimg.com/originals/10/2e/d7/102ed747bce1dab9c16768abe11549b4.jpg",
  },
  "slice-of-life": {
    genre: "Slice of Life",
    slug: "slice-of-life",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx10165-1j2Qn8wIIn5S.png",
  },
  sports: {
    genre: "Sports",
    slug: "sports",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx1764-8AxfOVdCcMr0.png",
  },
  supernatural: {
    genre: "Supernatural",
    slug: "supernatural",
    poster:
      "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx1535-lawCwhzhi96X.jpg",
  },
};
