import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

import { kurumiApi } from "../../api";

export const getWatchingEpisodes = createAsyncThunk(
  "homeState/getWatchingEpisodes",
  async () => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/home/watchingEpisodes`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    const { result } = data;

    return result.map((d: any) => ({
      ...d,
      episode: { ...d.episode, animeTitle: d.title },
    }));
  }
);

export const getLatestAnime = createAsyncThunk(
  "homeState/getLatestAnime",
  async () => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/home/latest?per_page=12`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    return data.result;
  }
);

export const getNewAnime = createAsyncThunk(
  "homeState/getNewAnime",
  async () => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/home/new?per_page=12`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    return data.result;
  }
);
