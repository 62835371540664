import { useState } from "react";

import { InputBox, InputField } from "./styles";

const Input = ({
  value,
  placeholder,
  isPassword = false,
  handleOnChange,
  handleOnSubmit,
}: {
  value: string;
  placeholder: string;
  isPassword?: boolean;
  handleOnChange: (str: string) => void;
  handleOnSubmit?: (str: string) => Promise<void> | void;
}) => {
  const [isActive, setActive] = useState(false);

  return (
    <InputField className="input-field" isActive={isActive}>
      <InputBox
        autoFocus
        multiple={true}
        value={value}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        type={isPassword ? "password" : "text"}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e.currentTarget.value)}
        onKeyDown={(e) =>
          e.code === "Enter" &&
          handleOnSubmit &&
          handleOnSubmit(e.currentTarget.value)
        }
      />
    </InputField>
  );
};

export default Input;
