import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../types";

import {
  getLatestAnime,
  getNewAnime,
  getWatchingEpisodes,
} from "./home.actions";
import { HomeState } from "./home.types";

const initialState = { loading: STATUS.IDLE } as HomeState;
const homeStateReducer = createSlice({
  name: "homeState",
  initialState,
  reducers: {
    setHomeLoadingStatus: (state, { payload }) => {
      return { ...state, loading: payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWatchingEpisodes.rejected, () => {
      window.location.replace("/login");
    });
    builder.addCase(getWatchingEpisodes.fulfilled, (state, { payload }) => {
      state.watchingEpList = payload;
    });

    builder.addCase(getLatestAnime.rejected, () => {
      window.location.replace("/login");
    });
    builder.addCase(getLatestAnime.fulfilled, (state, { payload }) => {
      state.latestList = payload;
    });

    builder.addCase(getNewAnime.rejected, () => {
      window.location.replace("/login");
    });
    builder.addCase(getNewAnime.fulfilled, (state, { payload }) => {
      state.newList = payload;
    });
  },
});

export const { setHomeLoadingStatus } = homeStateReducer.actions;
export default homeStateReducer.reducer;
