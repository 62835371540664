import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoginPage from "./pages/login";
import PrivateRoute from "./ui/PrivateRoute";
import Navbar from "./ui/components/Navbar";
import { setMedia } from "./store/app/reducer";
import { RootState } from "./store";
import FloatingActionButton from "./ui/components/FloatingActionButton";
import Toast from "./ui/components/Toast";

const HomePage = lazy(() => import("./pages/home"));
const AnimePage = lazy(() => import("./pages/anime"));
const EpisodePage = lazy(() => import("./pages/episode"));
const SearchPage = lazy(() => import("./pages/search"));
const SchedulePage = lazy(() => import("./pages/PageSchedule"));
const WatchPartyPage = lazy(() => import("./pages/PageWatchParty"));
const WatchPartyRoomPage = lazy(() => import("./pages/watchPartyRoom"));
const ProfilePage = lazy(() => import("./pages/profile"));
const ExplorePage = lazy(() => import("./pages/PageExplore"));
const ExploreGenrePage = lazy(() => import("./pages/PageExploreGenre"));
const InvitePage = lazy(() => import("./pages/invite"));

const App = () => {
  const isMobile = useSelector<RootState, RootState["app"]["isMobile"]>(
    (state) => state.app.isMobile
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const mql = window.matchMedia(
      "screen and (max-device-width: 493px), screen and (max-device-width: 926px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)"
    );

    const listener = (e: any) => {
      dispatch(setMedia(e.matches));
    };

    mql.addEventListener("change", listener);

    return () => mql.removeEventListener("change", listener);
  }, [dispatch]);

  return (
    <Router>
      {isMobile ? <FloatingActionButton /> : <Navbar />}
      <div style={{ zIndex: 200 }}>
        <Switch>
          <Route exact path="/">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={HomePage} />
            </Suspense>
          </Route>
          <Route exact path="/anime/:animeId">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={AnimePage} />
            </Suspense>
          </Route>
          <Route exact path="/search">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={SearchPage} />
            </Suspense>
          </Route>
          <Route exact path="/schedule">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={SchedulePage} />
            </Suspense>
          </Route>
          <Route exact path="/watchParty">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={WatchPartyPage} />
            </Suspense>
          </Route>
          <Route exact path="/watchParty/:roomId">
            <Suspense fallback={<></>}>
              <PrivateRoute
                isMobile={isMobile}
                component={WatchPartyRoomPage}
              />
            </Suspense>
          </Route>
          <Route exact path="/anime/:animeId/episode/:epNo">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={EpisodePage} />
            </Suspense>
          </Route>
          <Route exact path="/explore">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={ExplorePage} />
            </Suspense>
          </Route>
          <Route path="/explore/:slug">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={ExploreGenrePage} />
            </Suspense>
          </Route>
          <Route path="/profile">
            <Suspense fallback={<></>}>
              <PrivateRoute isMobile={isMobile} component={ProfilePage} />
            </Suspense>
          </Route>
          <Route path="/login">
            <Suspense fallback={<></>}>
              <LoginPage isMobile={isMobile} />
            </Suspense>
          </Route>
          <Route path="/invite">
            <Suspense fallback={<></>}>
              <InvitePage isMobile={isMobile} />
            </Suspense>
          </Route>
          <Route path="*"></Route>
        </Switch>
        <Toast />
      </div>
    </Router>
  );
};

export default App;
