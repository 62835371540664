import styled from "styled-components";

// export const SvgStyle = styled.svg<{ hoverColor: string }>`
//   margin-left: 4px;
//   padding: 4px;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: fill 0.3s ease-in;
//   transition: background-color 0.2s ease-in;

//   &:hover {
//     transition: fill 0.3s ease-out;
//     background-color: #fff3;
//     fill: ${({ hoverColor }) => hoverColor};
//   }
// `;

export const SvgStyle = styled.svg`
  margin-left: 4px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: fill 0.3s ease-in;
  transition: background-color 0.2s ease-in;

  &:hover {
    transition: fill 0.3s ease-out;
    background-color: #fff3;
  }

  &:focus {
    outline: none;
  }
`;
