import { Container, GenreItem } from "./styles";

const Genres = ({ genres }: { genres: string[] }) => {
  return (
    <Container>
      {genres.map((genre, i) => (
        <GenreItem to={`/explore/${genre.toLowerCase()}`} key={genre}>
          {i === genres.length - 1 ? genre : `${genre}  |  `}
        </GenreItem>
      ))}
    </Container>
  );
};

export default Genres;
