import { lazy, Suspense } from "react";

import { EpisodeGridElement } from "./styles";
import { Episode, MediaListStatus } from "../../../misc";

const EpisodeElement = lazy(() => import("../item"));

const EpisodeGrid = ({
  episodeList,
  progress,
  mediaListStatus,
  onHover,
  isLink,
  handleOnClick,
}: {
  episodeList: Episode[];
  progress: number | null;
  mediaListStatus: MediaListStatus | null;
  onHover?: (bannerImage: string) => void;
  isLink: boolean;
  handleOnClick?: (epNo: number) => void;
}) => (
  <EpisodeGridElement>
    {episodeList.map((episode: any) => (
      <Suspense fallback={<></>} key={episode.epId}>
        {progress !== null && mediaListStatus ? (
          episode.epNumber <= progress ? (
            <EpisodeElement
              {...episode}
              isLink={isLink}
              status={MediaListStatus.COMPLETED}
              key={episode.epId}
              onHover={onHover}
              handleOnClick={handleOnClick}
            />
          ) : (
            <EpisodeElement
              {...episode}
              isLink={isLink}
              status={mediaListStatus}
              key={episode.epId}
              onHover={onHover}
              handleOnClick={handleOnClick}
            />
          )
        ) : (
          <EpisodeElement
            {...episode}
            key={episode.epId}
            onHover={onHover}
            isLink={isLink}
            handleOnClick={handleOnClick}
          />
        )}
      </Suspense>
    ))}
  </EpisodeGridElement>
);

export default EpisodeGrid;
