import { useEffect, useRef, useState } from "react";

import { User } from "../../../store/user/types";
import { WatchPartyRoomAttr } from "../../misc";
import EmoteSelector from "../EmoteSelect";
import ChatItem from "./item";
import { ChatContainer, ChatInput, ChatMessages } from "./styles";

const Chat = ({
  chat: messages,
  user,
  handleSendMessage,
  room,
}: {
  chat: any[];
  user: User;
  handleSendMessage: any;
  room: WatchPartyRoomAttr;
}) => {
  const [text, setText] = useState<string>("");
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesEndRef) {
      messagesEndRef.current?.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        (target as any).scroll({
          top: (target as any).scrollHeight,
          behavior: "smooth",
        });
      });
    }
  }, []);

  return (
    <>
      <ChatContainer>
        <ChatMessages ref={messagesEndRef}>
          {messages.map((msg, i) => (
            <ChatItem
              key={i}
              chat={msg}
              continuous={
                i > 0 && messages[i - 1].userId === messages[i].userId
              }
            />
          ))}
        </ChatMessages>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1, margin: "0 0.375rem 0 0" }}>
            <ChatInput
              rows={2}
              placeholder="Let's say something"
              value={text.startsWith("\n") ? "" : text}
              onKeyDown={(e) => {
                if (e.shiftKey && e.key === "Enter") {
                } else if (e.key === "Enter") {
                  if (text.trim() !== "") {
                    handleSendMessage({
                      userId: user._id,
                      username: user.username,
                      roomId: room._id,
                      message: text,
                      time: new Date().toISOString(),
                      roleColor: user.role.color,
                    });
                  }
                  setText("");
                }
              }}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </div>
          <div>
            <EmoteSelector
              handleSelectEmote={(id: string) => {
                setText(` :${id}:`);
              }}
            />
          </div>
        </div>
      </ChatContainer>
    </>
  );
};

export default Chat;
