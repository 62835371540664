import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { kurumiApi } from "../../api";

export const createWatchPartyRoom = createAsyncThunk(
  "watchPartyState/createWatchPartyRoom",
  async (isPrivate: boolean) => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.post(
      `v2/watchParty/createRoom`,
      { isPrivate },
      {
        headers: {
          Authorization: `Bearer ${cookies.get("access_token")}`,
        },
      }
    );

    const { result } = data;
    return result;
  }
);

export const getWatchPartyRooms = createAsyncThunk(
  "watchPartyState/getWatchPartyRooms",
  async () => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/watchParty/getRooms`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    const { result } = data;
    return result;
  }
);

export const getWatchPartyRoom = createAsyncThunk(
  "watchPartyState/getWatchPartyRoom",
  async (roomId: string) => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/watchParty/${roomId}`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    const { result } = data;
    return result;
  }
);
