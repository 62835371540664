import { createSlice } from "@reduxjs/toolkit";

import { AppState } from "./types";

const appState = createSlice({
  name: "app",
  initialState: {
    isMobile: window.matchMedia(
      "screen and (max-device-width: 493px), screen and (max-device-width: 926px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)"
    ).matches,
  } as AppState,
  reducers: {
    setMedia: (state, { payload }) => {
      return { ...state, isMobile: payload };
    },
  },
});

export const { setMedia } = appState.actions;
export default appState.reducer;
