import styled from "styled-components";

export const ButtonPrimary = styled.button`
  background-color: #ef4444;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 1rem;
  min-width: 6rem;
  max-height: 3rem;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: #f87171;
    transition: background-color 0.2s ease-out;
  }

  &:focus {
    outline: none;
  }
`;
