import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../../types";
import { userAuth, userAuthCheck, userAuthWithInviteKey } from "./actions";
import { UserState } from "./types";

const initialState: UserState = {
  user: {
    _id: "",
    username: "",
    avatar: "",
    role: { roleName: "", color: "" },
  },
  isAuth: true,
  isAnilistAuth: false,
  loading: STATUS.IDLE,
};

const userStateReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthLoadingStatus: (state, { payload }) => {
      return { ...state, loading: payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userAuthWithInviteKey.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(userAuthWithInviteKey.rejected, (state) => {
      state.loading = STATUS.FAILED;
    });
    builder.addCase(userAuthWithInviteKey.fulfilled, (state) => {
      window.location.replace("/");
    });

    builder.addCase(userAuth.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(userAuth.fulfilled, (state) => {
      state.loading = STATUS.SUCCESS;
    });

    builder.addCase(userAuthCheck.pending, (state) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(userAuthCheck.rejected, () => {
      window.location.replace("/login");
    });
    builder.addCase(userAuthCheck.fulfilled, (state, { payload }) => {
      state.loading = STATUS.SUCCESS;

      state.user = payload.user;
      state.isAuth = true;
      if (payload?.anilist_token) {
        state.isAnilistAuth = true;
      } else {
        state.isAnilistAuth = false;
      }
    });
  },
});

export default userStateReducer.reducer;
