import React, { memo } from "react";
import { WatchPartyRoomAttr } from "../../../../store/watchParty/watchParty.types";
import WatchPartyRoomElement from "../item";
import { WatchPartyRoomGrid } from "./styles";

const WatchPartyRoomsGrid = ({ rooms }: { rooms: WatchPartyRoomAttr[] }) => (
  <WatchPartyRoomGrid>
    {rooms.map((room) => (
      <WatchPartyRoomElement {...room} key={room._id} />
    ))}
  </WatchPartyRoomGrid>
);

export default memo(
  WatchPartyRoomsGrid,
  (oldProps, newProps) => oldProps.rooms[0]?._id === newProps.rooms[0]?._id
);
