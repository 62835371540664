import styled from "styled-components";

export const AnimeGridElement = styled.div`
  margin-bottom: 1.6rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 1rem;

  @media screen and (min-width: 1720px) and (max-width: 1820px) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }

  @media screen and (min-width: 1520px) and (max-width: 1720px) {
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  }

  @media screen and (min-width: 1360px) and (max-width: 1520px) {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }

  @media screen and (min-width: 1170px) and (max-width: 1360px) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }

  @media screen and (min-width: 0px) and (max-width: 1170px) {
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  }
`;
