import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
`;

export const BannerImg = styled(Link)`
  display: flex;
  width: 3rem;
  height: 3rem;
  margin: 0.6rem;
  border-radius: 0.8rem;
  z-index: 300;
  text-decoration: none;

  img {
    width: 3rem;
    height: 3rem;
  }

  p {
    margin: 0;
    margin-left: 0.4rem;
    align-self: center;
    color: #f5f5f4;
    font-weight: 200;
    font-family: "anton";
    font-size: 1.6rem;
    font-style: italic;
  }

  .banner.suffix {
    margin-left: 0;
    color: #ef4444;
  }
`;

export const NavLink = styled(Link)<{ active: string }>`
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  margin: 0 0 1rem 0;
  text-decoration: none;
  border-radius: 0.8rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background-color: ${({ active }) =>
    active === "true" ? "#57534E" : undefined};
  transition: all 0.5s ease;

  &:hover {
    border-radius: 1rem;
    background-color: #44403c;
  }

  img {
    height: 54px;
  }

  @media screen and (max-width: 494px) {
    .banner.prefix,
    .banner.suffix {
      font-size: 1.8rem;
    }
  }

  svg {
    margin: auto;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #ef4444;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 12px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;
