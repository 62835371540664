import { UserContainer, UserPfp } from "./styles";

const UserListItem = ({ user }: { user: any }) => {
  return (
    <UserContainer>
      <UserPfp src={user.avatar} roleColor={user.role.color} />
    </UserContainer>
  );
};

export default UserListItem;
