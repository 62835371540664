import { createSlice } from "@reduxjs/toolkit";

import { getAnimeData, getAnimeEpisodes } from "./animeDetails.actions";
import { AnimeDetailsState } from "./animeDetails.types";

const initialState = {} as AnimeDetailsState;
const animeDetailsStateReducer = createSlice({
  name: "animeDetailsState",
  initialState,
  reducers: {
    setInitialState: (state) => {
      return {} as AnimeDetailsState;
    },
    setPageNo: (state, { payload }) => {
      return {
        ...state,
        episodeData: { ...state.episodesData, pageNo: payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnimeData.fulfilled, (state, { payload }) => {
      state.animeDetail = payload;
    });

    builder.addCase(getAnimeEpisodes.fulfilled, (state, { payload }) => {
      state.episodesData = {
        pageNo: payload.pageNo,
        showingResults: payload.showingResults,
        totalEpisodes: payload.totalEpisodes,
      };
      state.episodeList = payload.episodes;
    });
  },
});

export const { setPageNo, setInitialState } = animeDetailsStateReducer.actions;
export default animeDetailsStateReducer.reducer;
