import Axios from "axios";
import Cookies from "universal-cookie";
import { MediaListStatus } from "../types";

export const kurumiApi = Axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3002/api/"
      : "https://kurumi-tokisaki-api.herokuapp.com/api/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getAnimeEpisodeUrl = async (
  animeId: number,
  epNo: number
): Promise<any> => {
  const cookies = new Cookies();

  const { data } = await kurumiApi.get(`v2/anime/${animeId}/episodes/${epNo}`, {
    headers: {
      Authorization: `Bearer ${cookies.get("access_token")}`,
    },
  });

  return data;
};

export const putMarkEpAsComplete = async (epId: string): Promise<any> => {
  const cookies = new Cookies();

  const { data } = await kurumiApi.put(
    `v2/home/watchingEpisodes`,
    { epId },
    {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    }
  );

  return data;
};

export const addToList = async (
  animeId: number,
  status: MediaListStatus
): Promise<any> => {
  const cookies = new Cookies();

  const { data } = await kurumiApi.post(
    `v2/anilist/add_to_list`,
    { animeId, status },
    {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
        anilist_token: `Bearer ${cookies.get("anilist_token")}`,
      },
    }
  );

  return data;
};

export const markEpAsWatched = async (
  animeId: number,
  epNo: number
): Promise<any> => {
  const cookies = new Cookies();

  const { data } = await kurumiApi.post(
    `v2/anilist/mark_ep_as_watched`,
    { animeId, epNo },
    {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
        anilist_token: `Bearer ${cookies.get("anilist_token")}`,
      },
    }
  );

  return data;
};
