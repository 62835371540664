import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  box-sizing: border-box;
`;

export const GenreItem = styled(Link)`
  color: #fca5a5;
  display: inline;
  text-decoration: none;
`;
