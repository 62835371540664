import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { kurumiApi } from "../../api";

export const getScheduleResults = createAsyncThunk(
  "animeState/getScheduleResults",
  async () => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get(`v2/misc/schedule`, {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    const { result } = data;
    const res: any = [[], [], [], [], [], [], []];

    result.forEach((e: any) => {
      const date = new Date(e.releaseTime);
      const anime = e.anime;
      anime.year = date.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
      });

      res[(date.getDay() - new Date().getDay() + 7) % 7].push({
        ...e.anime,
        _id: e.animeId,
        releaseTime: e.releaseTime,
      });
    });

    for (let i = 0; i < 7; ++i) {
      res[i].sort((a: any, b: any) => {
        const bD = new Date(b.releaseTime);
        const aD = new Date(a.releaseTime);
        return (
          aD.getHours() - bD.getHours() || aD.getMinutes() - bD.getMinutes()
        );
      });
    }

    return res;
  }
);
