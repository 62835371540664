import ReactTooltip from "react-tooltip";

const PaginationTooltips = () => {
  return (
    <>
      <ReactTooltip
        id="pagination-first"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Go to first</p>
      </ReactTooltip>
      <ReactTooltip
        id="pagination-previous"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Go to previous</p>
      </ReactTooltip>
      <ReactTooltip
        id="pagination-next"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Go to next</p>
      </ReactTooltip>
      <ReactTooltip
        id="pagination-last"
        aria-haspopup="true"
        effect="solid"
        backgroundColor="#292524"
      >
        <p>Go to last</p>
      </ReactTooltip>
    </>
  );
};

export default PaginationTooltips;
