import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

import { kurumiApi } from "../../api";

export const userAuth = createAsyncThunk("user/userAuth", async () => {
  const cookies = new Cookies();
  const { data } = await kurumiApi.get("v2/user", {
    headers: {
      discord_access_token: cookies.get("discord_access_token"),
    },
  });

  const now = new Date();
  now.setDate(now.getDate() + 7);

  cookies.set("access_token", data.result.access_token, {
    path: "/",
    expires: now,
  });

  cookies.set("anilist_token", data.result.anilist_token, {
    path: "/",
    expires: now,
  });
});

export const userAuthWithInviteKey = createAsyncThunk(
  "user/userAuthWithInviteKey",
  async (inviteKey: string) => {
    const cookies = new Cookies();
    const { data } = await kurumiApi.post(
      "v2/user/useInviteKey",
      { inviteKey },
      {
        headers: {
          discord_access_token: cookies.get("discord_access_token"),
        },
      }
    );

    const now = new Date();
    now.setDate(now.getDate() + 7);

    cookies.set("access_token", data.result.access_token, {
      path: "/",
      expires: now,
    });
  }
);

export const userAuthCheck = createAsyncThunk(
  "user/userAuthCheck",
  async () => {
    const cookies = new Cookies();

    const { data } = await kurumiApi.get("v2/user/@me", {
      headers: {
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    });

    if (data.result?.anilist_token) {
      cookies.set("anilist_token", data.result.anilist_token, {
        path: "/",
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      });
    }

    return data.result;
  }
);
