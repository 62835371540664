import { TraceSpinner } from "react-spinners-kit";

import { LoadingAnimation } from "./styles";

const Loading = ({ height }: { height?: string }) => {
  return (
    <LoadingAnimation>
      <TraceSpinner size={48} frontColor={"#EF4444"} loading={true} />
    </LoadingAnimation>
  );
};

export default Loading;
