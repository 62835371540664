import styled from "styled-components";

export const Logo = styled.div`
  display: flex;
  width: 3rem;
  height: 3rem;
  margin: 0.6rem;
  border-radius: 0.8rem;

  img {
    width: 3rem;
    height: 3rem;
  }

  p {
    margin: 0;
    margin-left: 0.4rem;
    align-self: center;
    color: #f5f5f4;
    font-weight: 200;
    font-family: "anton";
    font-size: 1.6rem;
    font-style: italic;
  }

  .banner.suffix {
    margin-left: 0;
    color: #ef4444;
  }
`;

export const Footer = styled.div<{ footer: string }>`
  position: relative;
  width: 100%;
  height: 25vh;
  background-image: url(${({ footer }) => footer});
  background-position: 100% 5%;
  background-size: cover;

  p {
    margin-left: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;

    a {
      text-decoration: none;
      color: #ef4444;
    }
  }
`;
