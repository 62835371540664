import { toast } from "react-toastify";
import { MediaListStatus } from "../types";

export const showStatusToast = (status: MediaListStatus | string): void => {
  switch (status) {
    case MediaListStatus.CURRENT: {
      toast.dark(`Marked as "currently watching"`, {
        style: { color: "#C4B5FD" },
      });
      return;
    }

    case MediaListStatus.PLANNING: {
      toast.dark(`Marked as "planning to watch"`, {
        style: { color: "#7DD3FC" },
      });
      return;
    }

    case MediaListStatus.COMPLETED: {
      toast.dark(`Marked as "completed"`, {
        style: { color: "#BEF264" },
      });
      return;
    }

    case MediaListStatus.DROPPED: {
      toast.dark(`Marked as "dropped"`, {
        style: { color: "#ff5f71" },
      });
      return;
    }

    case MediaListStatus.PAUSED: {
      toast.dark(`Marked as "paused"`, {
        style: { color: "#FDE047" },
      });
      return;
    }

    case MediaListStatus.REPEATING: {
      toast.dark(`Marked as "repeating"`, {
        style: { color: "#e888f8" },
      });
      return;
    }

    default: {
      toast.dark(status, {
        style: { color: "#eee" },
      });
      return;
    }
  }
};

export const showIncorrectInviteToast = () => {
  toast.dark(`Incorrect invite key`, {
    style: { color: "#C4B5FD" },
  });
};
