import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const SvgElement = styled.span`
  margin: auto;
  vertical-align: middle;
  display: inline-block;
  width: 2rem;
  height: 2rem;
`;

export const Overlay = styled.div`
  display: grid;
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  padding: 0.8rem;
  opacity: 0;
`;

export const Container = styled(Link)`
  position: relative;
  text-decoration: none;
  border: 1px solid #2a2a2a;
  background-color: #1f1f1f;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 0.375rem;
  cursor: pointer;

  &:hover .poster {
    filter: blur(5px) brightness(40%);
  }

  &:hover .overlay {
    opacity: 1;
    transition: opacity 0.8s ease;
  }
`;

export const Poster = styled.img`
  display: block;
  width: 100%;
  aspect-ratio: 2 / 3;
  object-fit: cover;
  overflow: hidden;
  transition: filter 0.3s ease-out, transform 0.5s ease-out;
`;

export const TextContainer = styled.div`
  margin: 0;
  width: 100%;
  height: 4.2rem;
  padding: 0.5rem;
  box-sizing: border-box;
`;

export const TextTitle = styled.div`
  color: #ffffff;
  text-overflow: ellipsis;
  min-height: 2.4rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media screen and (max-width: 1550px) and (max-width: 1750px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 1430px) and (max-width: 1550px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 1430px) {
    font-size: 1rem;
  }

  @media screen and (max-device-width: 480px) {
    font-size: 1.6rem;
  }
`;

export const TextYear = styled.div`
  font-size: medium;
  font-weight: bold;
  font-size: 0.8rem;
  color: #666666;

  @media screen and (max-device-width: 480px) {
    font-size: 1.4rem;
  }
`;

export const TextDescription = styled.p`
  color: #ffffff;
  margin: 0;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  text-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 6px 16px rgb(0 0 0 / 12%),
    0 9px 28px rgb(0 0 0 / 7%);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16;
`;
