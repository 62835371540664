const ChatItem = ({ chat, continuous }: { chat: any; continuous: boolean }) => {
  return (
    <div
      style={{
        margin: continuous ? "2px 4px 0 4px" : "4px 4px 0 4px",
        padding: "6px",
        borderRadius: "0.375rem",
        backgroundColor: "#2a2a2a",
        width: "fit-content",
      }}
    >
      {!continuous && (
        <p
          style={{
            margin: 0,
            padding: 0,
            fontSize: "14px",
            color: chat.roleColor,
          }}
        >
          {chat.username}
        </p>
      )}
      <p
        style={{
          margin: 0,
          padding: 0,
          color: "white",
          whiteSpace: "pre-line",
          wordBreak: "break-all",
        }}
      >
        {chat.message}
      </p>
    </div>
  );
};

export default ChatItem;
