import styled from "styled-components";

export const EpisodeGridElement = styled.div`
  margin-bottom: 1.6rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-gap: 1rem;

  @media screen and (max-device-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
`;
