import {
  ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import Plyr, { PlyrEvent } from "plyr";
import Hls from "hls.js";
import $ from "jquery";

import { RootState, useAppDispatch, getWatchingEpisodes } from "../../store";

import "plyr/dist/plyr.css";
import "./player.css";
import { PlayerMessageAttrs } from "../../misc";
import { showStatusToast } from "../../../utils/toastMessage";

const rafAsync = () => {
  return new Promise((resolve) => {
    requestAnimationFrame(resolve); //faster than set time out
  });
};

// Allows rerunning
const checkElement = (
  selector: any,
  element: any,
  triggerOnRemoval = false
): any => {
  const check = triggerOnRemoval
    ? $(selector)[0] === element
    : document.querySelector(selector) === null || $(selector)[0] === element;
  if (check) {
    return rafAsync().then(() =>
      checkElement(selector, element, triggerOnRemoval)
    );
  } else {
    return Promise.resolve(true);
  }
};

const addPlayerButtons = (selector: any, element: any): any => {
  console.log("player rice");
  checkElement(selector, element).then(async (element: any) => {
    const isMobile = window.matchMedia(
      "screen and (max-device-width: 493px), screen and (max-device-width: 926px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)"
    ).matches;

    if (isMobile) {
      // remove volume controls
      $("div.plyr__controls > div.plyr__controls__item.plyr__volume").remove();

      // rewind
      $("div.plyr__controls > button:nth-child(1) > svg").replaceWith(`
        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" viewBox="0 0 20 20" fill="#fff">
          <path d="M8.445 14.832A1 1 0 0010 14v-2.798l5.445 3.63A1 1 0 0017 14V6a1 1 0 00-1.555-.832L10 8.798V6a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4z" />
        </svg>
      `);

      // forward
      $("div.plyr__controls > button:nth-child(3) > svg").replaceWith(`
        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" viewBox="0 0 20 20" fill="#fff">
          <path d="M4.555 5.168A1 1 0 003 6v8a1 1 0 001.555.832L10 11.202V14a1 1 0 001.555.832l6-4a1 1 0 000-1.664l-6-4A1 1 0 0010 6v2.798l-5.445-3.63z" />
        </svg>
      `);

      // pause-play icons
      $("div.plyr__controls > button:nth-child(2) > svg.icon--pressed")
        .replaceWith(`<svg xmlns="http://www.w3.org/2000/svg" class="icon--pressed" viewBox="0 0 20 20" fill="#fff" aria-hidden="true" focusable="false">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>`);

      $("div.plyr__controls > button:nth-child(2) > svg.icon--not-pressed")
        .replaceWith(`<svg xmlns="http://www.w3.org/2000/svg" class="icon--not-pressed" viewBox="0 0 20 20" fill="#fff" aria-hidden="true" focusable="false">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
        </svg>`);

      // settings
      $(
        "div.plyr__controls > div.plyr__controls__item.plyr__menu > button > svg"
      ).replaceWith(`
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
        </svg>
      `);
    } else {
      // skip op button
      $("div.plyr__controls > button:nth-child(1)").parent().prepend(`
      <a id="skip_button" onclick="document.getElementsByTagName('video')[0].currentTime += 85;" class="plyr__control" data-plyr="seekTime">
        <span class="plyr__tooltip">Skip OP</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
      </a>
      `);

      // pause-play icons
      $("div.plyr__controls > button:nth-child(2) > svg.icon--pressed")
        .replaceWith(`<svg xmlns="http://www.w3.org/2000/svg" class="icon--pressed" viewBox="0 0 20 20" fill="#fff" aria-hidden="true" focusable="false">
    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
  </svg>`);

      $("div.plyr__controls > button:nth-child(2) > svg.icon--not-pressed")
        .replaceWith(`<svg xmlns="http://www.w3.org/2000/svg" class="icon--not-pressed" viewBox="0 0 20 20" fill="#fff" aria-hidden="true" focusable="false">
    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
  </svg>`);

      $(
        "div.plyr__controls > div.plyr__controls__item.plyr__volume > button > svg.icon--pressed"
      ).replaceWith(`
        <svg xmlns="http://www.w3.org/2000/svg" class="icon--pressed" aria-hidden="true" viewBox="0 0 20 20" fill="#fff">
          <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      `);

      $(
        "div.plyr__controls > div.plyr__controls__item.plyr__volume > button > svg.icon--not-pressed"
      ).replaceWith(`
      <svg xmlns="http://www.w3.org/2000/svg" class="icon--not-pressed" aria-hidden="true" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
      </svg>
    `);

      $("div.plyr__controls > button:nth-child(9) > svg.icon--pressed")
        .replaceWith(`
      <svg xmlns="http://www.w3.org/2000/svg" class="icon--pressed" aria-hidden="true" viewBox="0 0 20 20" fill="#fff">
        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8V4m0 0h4M3 4l4 4m8 0V4m0 0h-4m4 0l-4 4m-8 4v4m0 0h4m-4 0l4-4m8 4l-4-4m4 4v-4m0 4h-4" />
      </svg>
    `);

      $("div.plyr__controls > button:nth-child(9) > svg.icon--not-pressed")
        .replaceWith(`
      <svg xmlns="http://www.w3.org/2000/svg" class="icon--not-pressed" aria-hidden="true" viewBox="0 0 20 20" fill="#fff">
        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8V4m0 0h4M3 4l4 4m8 0V4m0 0h-4m4 0l-4 4m-8 4v4m0 0h4m-4 0l4-4m8 4l-4-4m4 4v-4m0 4h-4" />
      </svg>
    `);

      // settings
      $(
        "div.plyr__controls > div.plyr__controls__item.plyr__menu > button > svg"
      ).replaceWith(`
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
      </svg>
    `);

      $("div.plyr__controls > button:nth-child(8) > svg").replaceWith(`
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 20 20" fill="#fff">
        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
      </svg>
    `);

      try {
        const isPrevEp =
          ($("p.prev-text")[0].attributes as any).color.value === "#ef4444";
        const isNextEp =
          ($("p.next-text")[0].attributes as any).color.value === "#ef4444";

        $(".plyr__controls__item.plyr__menu").append(`
        <a id="previous_ep_button" class="plyr__control next_button" data-plyr="seekTime">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill=${
            isPrevEp ? "#fff" : "#ddd"
          }>
            <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
          <span class="plyr__tooltip">Previous Episode</span>
        </a>
        <a id="next_ep_button" class="plyr__control prev_button" data-plyr="seekTime">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill=${
            isNextEp ? "#fff" : "#ddd"
          }>
            <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <span class="plyr__tooltip">Next Episode</span>
        </a>
      `);
      } catch (err) {}

      try {
        $("a#previous_ep_button")[0].onclick = () => {
          $("p.prev-text")[0].click();
        };
        $("a#next_ep_button")[0].onclick = () => {
          $("p.next-text")[0].click();
        };
      } catch (err) {}

      // return addPlayerButtons("div.plyr__controls", $("div.plyr__controls")[0]);
    }
  });
};

const Player = (
  props: {
    stream: string;
    animeId: number;
    epNo: number;
    epId: string;
    handleMarkAsWatched: (animeId: number, epNo: number) => Promise<void>;
    handleOnPause?: (msg: any) => void;
    handleOnPlay?: any;
    emit: (event: string, msg: any) => void;
    emitPlayerEvents: (event: string, msg: PlayerMessageAttrs) => void;
    isMaster?: boolean;
    isRoom: boolean;
  },
  ref?: ForwardedRef<
    | {
        onPause: (time: number) => void;
        onPlay: (time: number) => void;
        onSeeked: (time: number, playing: boolean) => void;
        onGetTime: () => void;
      }
    | undefined
  >
) => {
  const {
    animeId,
    emit,
    epId,
    epNo,
    handleMarkAsWatched,
    stream,
    isMaster,
    emitPlayerEvents,
    isRoom,
  } = props;
  const watchingList = useSelector<
    RootState,
    RootState["homeState"]["watchingEpList"]
  >((state) => state.homeState.watchingEpList);
  const plyrRef = useRef<Plyr>();

  const isMobile = useSelector<RootState, RootState["app"]["isMobile"]>(
    (state) => state.app.isMobile
  );

  const user = useSelector<RootState, RootState["user"]["user"]>(
    (state) => state.user.user
  );

  const streamUrl = atob(stream);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWatchingEpisodes());
    addPlayerButtons("div.plyr__controls", "");
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    onPause: (time) => {
      if (isMaster) return;
      plyrRef.current!.currentTime = time;
      plyrRef.current!.pause();
    },
    onPlay: (time) => {
      if (isMaster) return;
      plyrRef.current!.currentTime = time;
      plyrRef.current!.play();
    },
    onSeeked: async (time: number, playing: boolean) => {
      if (isMaster) return;
      plyrRef.current!.currentTime = time;
      if (playing) {
        await plyrRef.current!.play();
      } else {
        plyrRef.current!.pause();
      }
    },
    onGetTime: () => {
      if (!isMaster) return;
      emitPlayerEvents("seeked", {
        time: plyrRef.current!.currentTime,
        playing: plyrRef.current!.playing,
      });
    },
  }));

  useEffect(() => {
    if (!watchingList || !user) return;

    const element = document.querySelector("video");
    const startFrom =
      (watchingList.find((e) => e.episode.epId === epId) ?? {}).episode
        ?.currentTime ?? 0;

    let options: Plyr.Options = {
      i18n: {
        play: "Play (K)",
        pause: "Pause (K)",
        mute: "Mute (M)",
        unmute: "Unmute (M)",
        enterFullscreen: "Enter fullscreen (F)",
        exitFullscreen: "Exit fullscreen (F)",
      },
      autoplay: true,
      tooltips: { controls: true, seek: true },
      seekTime: 5,
      keyboard:
        isRoom && !isMaster
          ? { focused: false, global: false }
          : { focused: true, global: true },
      fullscreen: { iosNative: true },
      speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
      listeners:
        isRoom && !isMaster
          ? {
              seek: (e) => false,
              pause: (e) => false,
              play: (e) => false,
              restart: (e) => false,
              rewind: (e) => false,
            }
          : {},
    };

    if (isMobile) {
      options.controls = [
        "play-large",
        "rewind",
        "play",
        "fast-forward",
        "progress",
        "current-time",
        "mute",
        "volume",
        "settings",
        "fullscreen",
      ];
    }

    if (streamUrl.includes("storage.googleapis")) {
      plyrRef.current = new Plyr(element!, options);

      let runningInterval: NodeJS.Timeout;

      plyrRef.current.source = {
        sources: [{ src: streamUrl, type: "video/mp4", size: 1080 }],
        type: "video",
      };

      attachProgressListener(startFrom).then((data) => {
        runningInterval = data;
      });

      return () => {
        clearInterval(runningInterval);
        plyrRef.current && plyrRef.current.destroy();
      };
    } else if (Hls.isSupported() && streamUrl.includes("m3u8")) {
      const hls = new Hls({ enableWorker: true });

      let runningInterval: NodeJS.Timeout;

      hls.loadSource(streamUrl);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const qualityLevels = hls.levels.map((level) => level.height).reverse();

        options.quality = {
          default: qualityLevels[0],
          options: qualityLevels,
          forced: true,

          onChange: (quality: number) => {
            const t = quality;
            hls.levels.forEach((e, n) => {
              if (e.height === t) {
                hls.currentLevel = n;
              }
            });
          },
        };
        plyrRef.current = new Plyr(element!, options);

        attachProgressListener(startFrom).then((data) => {
          runningInterval = data;
        });
      });

      hls.attachMedia(element!);

      return () => {
        clearInterval(runningInterval);
        hls && hls.destroy();
        plyrRef.current && plyrRef.current.destroy();
      };
    } else if (streamUrl.includes("m3u8")) {
      plyrRef.current = new Plyr(element!, options);

      let runningInterval: NodeJS.Timeout;

      plyrRef.current.source = { type: "video", sources: [{ src: streamUrl }] };
      plyrRef.current.play();

      attachProgressListener(startFrom).then((data) => {
        runningInterval = data;
      });

      return () => {
        clearInterval(runningInterval);
        plyrRef.current && plyrRef.current.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchingList, streamUrl, epId, animeId, user, epNo, handleMarkAsWatched]);

  const interval = (cb: () => void) =>
    setInterval(async () => {
      const { currentTime, duration } = plyrRef.current!;

      if (currentTime / duration > 0.85) {
        emit("setFinished", {
          userId: user._id,
          animeId,
          epId,
          currentTime,
        });

        await handleMarkAsWatched(animeId, epNo);
        return cb();
      }

      emit("updateTime", {
        userId: user._id,
        animeId,
        epId,
        currentTime,
      });
    }, 10 * 1000);

  const attachProgressListener = (startFrom: number): Promise<any> => {
    let runningInterval: NodeJS.Timeout;

    if (isRoom && isMaster) {
      plyrRef.current!.on("seeked", function (this: Plyr, event: PlyrEvent) {
        emitPlayerEvents("seeked", {
          time: plyrRef.current!.currentTime,
          playing: plyrRef.current!.playing,
        });
      });
      plyrRef.current!.on("pause", () => {
        emitPlayerEvents("pause", {
          time: plyrRef.current!.currentTime,
          playing: false,
        });
      });
      plyrRef.current!.on("play", () => {
        emitPlayerEvents("play", {
          time: plyrRef.current!.currentTime,
          playing: true,
        });
      });
    }

    return new Promise((resolve) => {
      plyrRef.current!.once("canplay", async () => {
        emit("getTime", {});

        runningInterval = interval(() => clearInterval(runningInterval));

        emit("setStart", { userId: user._id, animeId, epId });
        try {
          await plyrRef.current!.play();
        } catch {
          showStatusToast(
            "Failed to play the video with audio due to Autoplay Policy. Unmute the video using (M) key or sliding the volume"
          );
          plyrRef.current!.muted = true;
          await plyrRef.current!.play();
        }

        return resolve(runningInterval);
      });
    });
  };

  return (
    <div>
      <video
        autoPlay
        playsInline
        style={{
          width: "100%",
          aspectRatio: "16/9",
        }}
      />
    </div>
  );
};

const PlayerFwdRef = forwardRef(Player);

export default memo(PlayerFwdRef, (oldProps, newProps) => {
  return oldProps.stream === newProps.stream;
});
