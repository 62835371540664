import styled from "styled-components";

export const MainContainer = styled.div`
  grid-area: main;
`;

export const KurumiBg = styled.div<{ img: string }>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 25vh;
  background-image: url(${({ img }) => img});
  background-position: 100% 5%;
  background-size: cover;
`;
