import { ButtonPrimary } from "./styles";

const Button = ({
  label,
  handleSubmit,
}: {
  label: string;
  handleSubmit: () => any;
}) => {
  return <ButtonPrimary onClick={handleSubmit}>{label}</ButtonPrimary>;
};

export default Button;
