import {
  Container,
  Overlay,
  Poster,
  Placeholder,
  TextContainer,
  TextDescription,
  TextTitle,
  TextYear,
} from "./styles";
import { WatchPartyRoomAttr } from "../../../misc";

const WatchPartyRoomElement = ({
  _id,
  creator,
  creatorName,
  members,
  animeId,
  epNumber,
  epId,
  viewing,
  episode,
  title,
}: WatchPartyRoomAttr) => (
  <Container style={{ textDecoration: "none" }} to={`/watchParty/${_id}`}>
    {episode ? (
      <Poster
        className="poster"
        src={episode.thumbnail}
        alt={episode.thumbnail}
      />
    ) : (
      <Placeholder className="poster">
        <p
          style={{
            textDecoration: "none",
            color: "#4a4a4a",
            fontSize: "4rem",
            margin: 0,
          }}
        >
          Idling
        </p>
      </Placeholder>
    )}
    <TextContainer>
      <TextTitle>{creatorName}'s room</TextTitle>
      <TextYear>
        {viewing > 1 ? `${viewing} viewers` : `${viewing} viewer`}
      </TextYear>
    </TextContainer>
    <Overlay className="overlay">
      {title?.english && epNumber && episode?.description && (
        <TextDescription
          dangerouslySetInnerHTML={{
            __html: `
        <p style='margin-top: 0; margin-bottom: 8px;'>Now Playing: ${title?.english}</p>
        <p style='margin-top: 0; margin-bottom: 8px;'>Episode: ${epNumber}</p>
        <p style='margin-top: 0; margin-bottom: 8px;'>${episode?.description}<p>
      `,
          }}
        />
      )}
    </Overlay>
  </Container>
);

export default WatchPartyRoomElement;
