import { memo } from "react";
import { Flipper } from "react-flip-toolkit";

import AnimeItem from "../item";
import { AnimeGridElement } from "./styles";
import { Anime, SORT_BY, SORT_ORDER } from "../../../misc";

const AnimeGrid = ({
  animeList,
  sortBy,
  sortOrder,
  onHover,
  isLink,
  handleClick,
}: {
  animeList: Anime[];
  sortBy: SORT_BY;
  sortOrder: SORT_ORDER;
  onHover?: (bannerImage: string) => void;
  isLink?: boolean;
  handleClick?: (animeId: number) => void;
}) => (
  <Flipper
    flipKey={`${sortOrder}-${sortBy}-kkk`}
    spring="noWobble"
    staggerConfig={{
      default: {
        reverse: false,
        speed: 2,
      },
    }}
  >
    <AnimeGridElement>
      {animeList.map((anime) => (
        <AnimeItem
          {...anime}
          key={anime._id}
          onHover={onHover}
          isLink={isLink}
          handleClick={handleClick}
        />
      ))}
    </AnimeGridElement>
  </Flipper>
);

export default memo(
  AnimeGrid,
  (oldProps, newProps) =>
    oldProps.animeList[0]?._id === newProps.animeList[0]?._id
);
