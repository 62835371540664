import styled from "styled-components";

export const ButtonStyle = styled.span<{
  bg: string;
  hoverBg: string;
  activeBg: string;
}>`
  margin: 0 1rem 0 0;
  min-width: 9rem;
  display: inline-block;
  background-color: ${({ bg }) => bg};
  border-radius: 0.2rem;
  padding: 0.5rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ hoverBg }) => hoverBg};
  }

  &:active {
    background-color: ${({ activeBg }) => activeBg};
  }

  span {
    display: inline-block;
    vertical-align: middle;
    transition: color 0.2s ease-in-out;
  }
`;

export const ButtonIconStyle = styled.span<{ color: string }>`
  margin: auto;
  margin-right: 4px;
  width: 1rem;
  height: 1rem;
  margin-right: 0.6rem;

  svg {
    fill: ${({ color }) => color};
  }
`;

export const ButtonTextStyle = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
`;
