import { Loading } from "../../components";
import {
  ButtonStyle,
  HeadingStyle,
  LeftPaddingStyle,
  RightPaddingStyle,
  RootContainerStyle,
} from "../../styles";
import { STATUS } from "../../misc";

import kuru from "../../../assets/images/kurumi.png";
import { MainContainer } from "./styles";

const LoginContainer = ({ loading }: { loading: any }) => (
  <RootContainerStyle>
    <LeftPaddingStyle />

    <MainContainer>
      {loading === STATUS.LOADING ? (
        <div style={{ display: "grid", height: "100vh" }}>
          <Loading />
        </div>
      ) : (
        <>
          <img
            src={kuru}
            alt=""
            style={{ position: "absolute", right: 0, bottom: 0 }}
          />
          <HeadingStyle>Login</HeadingStyle>
          <ButtonStyle
            bg="#ef4444"
            hoverBg="#f32e26"
            activeBg="#ef4444"
            onClick={() =>
              (window.location.href =
                process.env.NODE_ENV === "development"
                  ? "https://discord.com/api/oauth2/authorize?client_id=813683955265699891&redirect_uri=http%3A%2F%2Flocalhost:3000%2Flogin%2Foauth&response_type=token&scope=identify%20guilds&prompt=none"
                  : "https://discord.com/api/oauth2/authorize?client_id=813683955265699891&redirect_uri=https%3A%2F%2Fanikuru.me%2Flogin%2Foauth&response_type=token&scope=identify%20guilds&prompt=none")
            }
          >
            Login with Discord
          </ButtonStyle>
          <br />
          <ButtonStyle
            bg="#ef4444"
            hoverBg="#f32e26"
            activeBg="#ef4444"
            onClick={() =>
              (window.location.href =
                process.env.NODE_ENV === "development"
                  ? "https://discord.com/api/oauth2/authorize?client_id=813683955265699891&redirect_uri=http%3A%2F%2Flocalhost:3000%2Finvite%2Foauth&response_type=token&scope=identify%20guilds%20guilds.join&prompt=none"
                  : "https://discord.com/api/oauth2/authorize?client_id=813683955265699891&redirect_uri=https%3A%2F%2Fanikuru.me%2Finvite%2Foauth&response_type=token&scope=identify%20guilds%20guilds.join&prompt=none")
            }
          >
            I have an Invite!
          </ButtonStyle>
        </>
      )}
    </MainContainer>
    <RightPaddingStyle />
  </RootContainerStyle>
);

export default LoginContainer;
