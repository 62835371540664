import styled from "styled-components";

export const RootContainerStyle = styled.div`
  display: grid;
  grid-template-columns: 0.15fr 1fr 0.15fr;
  grid-template-rows: minmax(100vh, auto);
  grid-template-areas: "left-padding main right-padding";
`;

export const LeftPaddingStyle = styled.div`
  grid-area: left-padding;
`;

export const RightPaddingStyle = styled.div`
  grid-area: right-padding;
`;

export const MainContainer = styled.div`
  grid-area: main;
`;
