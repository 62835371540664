import { useRef, useState } from "react";

import { MediaListStatus, useOutsideClickHook } from "../../misc";

import {
  FAB,
  Container,
  StatusMenu,
  StatusContainer,
  StatusIcon,
  StatusText,
} from "./styles";

const MediaListMenu = ({
  currentStatus,
  handleStatusChange,
}: {
  currentStatus: MediaListStatus | null;
  handleStatusChange: (status: MediaListStatus) => any;
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  useOutsideClickHook(wrapperRef, () => setOpen(false));

  const handleClick = (status: MediaListStatus) => {
    handleStatusChange(status);
    setOpen(false);
  };

  const mediaListToSvg = {
    NULL: (
      <>
        <StatusIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#7DD3FC"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </StatusIcon>
        <StatusText>Add to List</StatusText>
      </>
    ),

    CURRENT: (
      <>
        <StatusIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#C4B5FD"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </StatusIcon>
        <StatusText>Current</StatusText>
      </>
    ),

    PLANNING: (
      <>
        <StatusIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#7DD3FC"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </StatusIcon>
        <StatusText>Planning</StatusText>
      </>
    ),

    COMPLETED: (
      <>
        <StatusIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#BEF264"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </StatusIcon>
        <StatusText>Completed</StatusText>
      </>
    ),

    DROPPED: (
      <>
        <StatusIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#ff5f71"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </StatusIcon>
        <StatusText>Dropped</StatusText>
      </>
    ),

    PAUSED: (
      <>
        <StatusIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#FDE047"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </StatusIcon>
        <StatusText>Paused</StatusText>
      </>
    ),

    REPEATING: (
      <>
        <StatusIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#e888f8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </StatusIcon>
        <StatusText>Repeating</StatusText>
      </>
    ),

    REMOVE: <></>,
  };

  return (
    <div ref={wrapperRef} style={{ width: "fit-content" }}>
      <FAB onClick={() => setOpen(!isOpen)}>
        <StatusContainer onClick={() => setOpen(false)}>
          {currentStatus === null
            ? mediaListToSvg["NULL"]
            : mediaListToSvg[currentStatus]}
        </StatusContainer>
      </FAB>
      <Container visible={isOpen}>
        <StatusMenu>
          <StatusContainer
            onClick={() => handleClick(MediaListStatus.COMPLETED)}
          >
            {mediaListToSvg.COMPLETED}
          </StatusContainer>
          <StatusContainer onClick={() => handleClick(MediaListStatus.CURRENT)}>
            {mediaListToSvg.CURRENT}
          </StatusContainer>
          <StatusContainer onClick={() => handleClick(MediaListStatus.DROPPED)}>
            {mediaListToSvg.DROPPED}
          </StatusContainer>
          <StatusContainer onClick={() => handleClick(MediaListStatus.PAUSED)}>
            {mediaListToSvg.PAUSED}
          </StatusContainer>
          <StatusContainer
            onClick={() => handleClick(MediaListStatus.PLANNING)}
          >
            {mediaListToSvg.PLANNING}
          </StatusContainer>
          <StatusContainer
            onClick={() => handleClick(MediaListStatus.REPEATING)}
          >
            {mediaListToSvg.REPEATING}
          </StatusContainer>
        </StatusMenu>
      </Container>
    </div>
  );
};

export default MediaListMenu;
