import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../types";
import {
  createWatchPartyRoom,
  getWatchPartyRooms,
  getWatchPartyRoom,
} from "./watchParty.action";

import { WatchPartyState } from "./watchParty.types";

const initialState = {
  watchPartyRooms: [],
  currentRoom: {} as any,
  loading: STATUS.IDLE,
} as WatchPartyState;
const watchPartyStateReducer = createSlice({
  name: "watchPartyState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createWatchPartyRoom.fulfilled, (state, { payload }) => {
      window.location.replace(`watchParty/${payload._id}`);
    });

    builder.addCase(getWatchPartyRoom.fulfilled, (state, { payload }) => {
      state.loading = STATUS.SUCCESS;

      state.currentRoom = payload;
    });

    builder.addCase(getWatchPartyRooms.pending, (state, { payload }) => {
      state.loading = STATUS.LOADING;
    });

    builder.addCase(getWatchPartyRooms.fulfilled, (state, { payload }) => {
      state.watchPartyRooms = payload;
      state.loading = STATUS.LOADING;
    });
  },
});

export default watchPartyStateReducer.reducer;
