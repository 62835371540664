import { WatchPartyRoomAttr } from "./ui/misc";

export enum SORT_BY {
  TITLE,
  YEAR,
  RATING,
  POPULARITY,
  NONE,
}

export enum SORT_ORDER {
  DESC,
  ASC,
}

export enum STATUS {
  IDLE,
  LOADING,
  FAILED,
  SUCCESS,
}

export enum MediaListStatus {
  CURRENT = "CURRENT",
  PLANNING = "PLANNING",
  COMPLETED = "COMPLETED",
  DROPPED = "DROPPED",
  PAUSED = "PAUSED",
  REPEATING = "REPEATING",
  REMOVE = "REMOVE",
}

export type EpisodeStream = {
  episode: {
    description: string;
    epId: string;
    epNumber: number;
    streams: string;
    title: string;
    thumbnail: string;
  };
  title: {
    romaji: string;
    english: string;
    native: string;
  };
  firstEpNumber: number;
  lastEpNumber: number;
  nextEpNumber: number;
  previousEpNumber: number;
};

export type ChatMessageAttrs = {
  message: string;
  roomId: string;
  time: string;
  userId: string;
  username: string;
  clientId: string;
};

export type JoinMessageAttrs = {
  roomId: string;
} & WatchPartyRoomAttr["members"][0];

export type PlayerMessageAttrs = {
  roomId?: string;
  time: number;
  playing: boolean;
};
