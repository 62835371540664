import styled from "styled-components";

export const UserGrid = styled.div`
  width: 20.5rem;
  max-height: 8rem;
  
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(3.5rem, 1fr));
  grid-gap: 0.75rem;

  ::-webkit-scrollbar {
    display: none;
  }
`;
