import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { RootState, useAppDispatch } from "../../store";
import { userAuth } from "../../store/user/actions";
import { STATUS } from "../../types";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useLoginPage = () => {
  const loading = useSelector<RootState, RootState["user"]["loading"]>(
    (state) => state.user.loading
  );

  const query = useQuery();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const cookies = new Cookies();
    const now = new Date();
    now.setDate(now.getDate() + 7);

    (async () => {
      if (loading === STATUS.IDLE) {
        const discordAccessToken = location.hash
          .replace("#token_type=Bearer&access_token=", "")
          .replace("&expires_in=604800&scope=identify+guilds", "");
        if (discordAccessToken) {
          cookies.set("discord_access_token", discordAccessToken, {
            path: "/",
            expires: now,
          });

          await dispatch(userAuth());
          history.replace("/");
        }
      }
    })();
  }, [dispatch, query, loading, history, location.hash]);

  return { loading };
};

export default useLoginPage;
