import styled from "styled-components";

export const Theater = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
`;

export const Player = styled.iframe`
  display: block;
  position: absolute;
  border: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;
