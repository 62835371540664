import { WatchPartyRoomAttr } from "../../../../store/watchParty/watchParty.types";
import UserListItem from "../item";
import { UserGrid } from "./styles";

const UserListGrid = ({ users }: { users: WatchPartyRoomAttr["members"] }) => {
  return (
    <UserGrid>
      {users.map((user) => (
        <UserListItem key={user.userId} user={user} />
      ))}
    </UserGrid>
  );
};

export default UserListGrid;
